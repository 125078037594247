<template>
  <b-card>
    <h4><i class="nav-icon icon-layers"></i> Authority Levels</h4>
    <hr>
    <h5>Select Project <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover
        title="Project manager is project based. Manager is global."></i></h5>
    <select class="select-account form-control w-50 mb-4" style="height: 42px;" name="editprojectid" v-model="projectid"
      @change="loadData()">
      <option value="0" disabled selected>Select Project</option>
      <option for v-for="option in projectData" v-bind:value="option.projectid">{{ option.projectname }}</option>
    </select>

    <v-client-table v-show="loading" :columns="columns" :data="wtsData" :options="options" :theme="theme"
      id="dataTable">
      <div slot="type" slot-scope="props">
        <span v-if="props.row.authority">
          Manager ({{ props.row.authority }})
        </span>
        <span v-else>
          Project Manager
        </span>
      </div>
      <div slot="passlimit" slot-scope="props">
        ${{ props.row.passlimit }}
      </div>

      <div slot="actions" slot-scope="props">
        <b-button size="sm" v-b-modal.editModal variant="primary"
          @click="sendId(props.row.authority, props.row.wtsid, props.row.managerid, props.row.passlimit)">Edit Level <i
            class="fa fa-edit"></i></b-button>
      </div>
    </v-client-table>
    <b-modal id="editModal" @shown="error = false" ref="editModal" title="Edit Limit" title-tag="h2" centered size="lg"
      hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label for="">Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control"
            placeholder="$100000" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The pass limit is required.</span>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error.
          Please contact admin. </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import Vue from 'vue'
import { ClientTable, Event } from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'ManagerAuthority',
  components: {
    ClientTable,
    Event,
  },
  data() {
    return {
      columns: ['type', 'fname', 'lname', 'passlimit', 'actions'],
      wtsData: [],
      options: {
        headings: {
          type: 'Type (Authority Level)',
          fname: 'First Name',
          lname: 'Last Name',
          passlimit: 'Pass Limit',
        },
        sortable: ['authority'],
        filterable: false,
        sortIcon: { base: 'fa', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort' },
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      id: "",
      error: false,
      projectData: [],
      projectid: '0',
      unique: false,
      assignerror: false,
      passlimit: 0,
      loading: false,
      wtsid: 0,
      managerid: 0,
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.authority) {
            let data = JSON.stringify({
              id: this.managerid,
              passlimit: this.passlimit
            })
            this.$http.post('/accountant/edit/manager_passlimit', data)
              .then(response => {
                this.$refs.editModal.hide();
                this.loadData();
              })
              .catch(error => {
                this.error = true;
              })
          }
          else {
            let data = JSON.stringify({
              id: this.wtsid,
              passlimit: this.passlimit,
              projectid: this.projectid
            })
            this.$http.post('/accountant/edit/wts_passlimit', data)
              .then(response => {
                this.$refs.editModal.hide();
                this.loadData();
              })
              .catch(error => {
                this.error = true;
              })
          }
          return;
        }
      });
    },
    sendId(authority, wtsid, managerid, passlimit) {
      this.authority = authority;
      this.wtsid = wtsid;
      this.managerid = managerid;
      this.passlimit = passlimit;
    },
    loadData() {
      this.loading = false;
      this.$http.get('/manager/get/wts_projects')
        .then(response => {
          var temp = response.data.result;
          this.wtsData = temp.filter(x => x.projectid === this.projectid)
          return this.$http.get('/manager/get/managers')
        })
        .then(response => {
          this.wtsData = this.wtsData.concat(response.data.result);
          this.loading = true;
        })
        .catch(error => {
        })
    },
    loadProjects() {
      this.$http.get('/manager/get/projects')
        .then(response => {
          this.projectData = response.data.result;
        })
        .catch(error => {
          this.$toasted.show('Error.', { type: 'error', duration: '3000' })
        })
    }
  },
  mounted: function () {
    this.loadProjects();
  },
}
</script>
